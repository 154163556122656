body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.aor-rich-text-input .ql-editor {
    font-family: "Humanst521 BT";
    font-size: 9pt;
}

.ColorInput-popup {
    position: absolute;
    z-index: 2;
}

.ColorInput-cover {
    position: fixed;
    top: 0; bottom: 0;
    left: 0; right: 0;
}
.ColorInput-popup {
    position: absolute;
    z-index: 2;
}

.ColorInput-cover {
    position: fixed;
    top: 0; bottom: 0;
    left: 0; right: 0;
}
.detail p,.detail span, .detail td, .detail tr {
    font-size: 10px !important;
    
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
    white-space: normal;
}

